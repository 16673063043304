import "./online_controller.scss";

import { Controller } from "@hotwired/stimulus";
import { Subscription } from "@rails/actioncable";
import { consumer } from "../../javascript/lib/cable";
import { emitter } from "../../javascript/lib/emitter";
import { minBlock } from "../../javascript/lib/minblock";
import { getLocation } from "../../javascript/lib/window";
import Logger from "./logger";

export type TUserID = string;
export interface IUser {
  user_id: string;
  user_name: string;
  location: string;
  focus: boolean;
}

export default class OnlineController extends Controller {
  static targets = ["onlineUsersAvatars"];
  declare readonly onlineUsersAvatarsTarget: HTMLDivElement;

  currentUserIdValue!: string;
  onlineUsersMetaValue!: IUser[];

  onlinePub!: Subscription;
  focusTimer!: number;
  currentFocus!: boolean;

  activeUsers = new Map<TUserID, IUser>();

  userAvatars = new Map<TUserID, HTMLCanvasElement>();

  colorsList: string[] = [];
  userColors = new Map<TUserID, string>();
  colorUsers = new Map<string, TUserID>();

  location = "";

  remoteReceivedTimestamp = 0;
  localReceivedTimestamp = 0;
  latestSentTimestamp = 0;
  messagesCount = 0;

  logger = new Logger("OnlineController");

  connect() {
    Promise.resolve().then(() => {
      this.location = getLocation();
      this.colorsList = this.generateContrastingColors(1000);

      this.currentUserIdValue = this.element.getAttribute(
        "data-current-user-id",
      ) as string;

      this.onlineUsersMetaValue = JSON.parse(
        this.element.getAttribute("data-online-users-meta") as string,
      );
      if (this.onlineUsersMetaValue) {
        this.onlineUsersMetaValue.forEach((user) => {
          this.onActiveUserOnline(user);
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const controller = this;
      this.onlinePub = consumer.subscriptions.create(
        { channel: "OnlineChannel" },
        {
          received(data) {
            controller.logger.log("received", data);

            if (data.ts <= controller.remoteReceivedTimestamp) {
              controller.logger.log("received old message");
              return;
            }
            controller.remoteReceivedTimestamp = data.ts;
            controller.localReceivedTimestamp = Date.now() / 1000;
            controller.messagesCount += 1;

            if (data.status === "online") {
              controller.onActiveUserOnline(data);
            } else if (data.status === "offline") {
              controller.onActiveUserOffline(data);
            }
          },
          appear() {
            controller.logger.log("appear");
          },
          away() {
            controller.logger.log("away");
          },
          connected() {
            controller.logger.log("connected");
            controller.installWatcher();
          },
          disconnected() {
            controller.logger.log("disconnected");
            controller.onlinePub.unsubscribe();
          },
          rejected() {
            controller.logger.log("rejected");
            controller.onlinePub.unsubscribe();
          },
        },
      );
    });
  }

  getCurrentUserId() {
    return this.currentUserIdValue;
  }

  getCurrentUser() {
    return this.activeUsers.get(this.getCurrentUserId());
  }

  onActiveUserOnline(data: IUser) {
    const user = this.findActiveUser(data);
    if (!user) {
      this.activeUsers.set(data.user_id, data);
      this.assignUserColor(data);
    }
    this.refreshAvatarCanvas(data);
  }

  generateContrastingColors(numColors: number) {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const hue = 240 - (i % 2) * 120;
      const saturation = 100;
      const lightness = 20 + (i % 2) * 20;
      colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
    }
    return colors;
  }

  generateUserColor() {
    const color = this.colorsList.pop();
    if (!color) throw new Error("No more colors");

    return color;
  }

  assignUserColor(data: IUser) {
    if (this.userColors.has(data.user_id)) return;

    const color = this.generateUserColor();
    this.userColors.set(data.user_id, color);
    this.colorUsers.set(color, data.user_id);
  }

  getUserColor(userId: TUserID) {
    return this.userColors.get(userId);
  }

  removeUserColor(data: IUser) {
    const color = this.userColors.get(data.user_id);
    if (!color) return;

    this.userColors.delete(data.user_id);
    this.colorUsers.delete(color);
    this.colorsList.push(color);
  }

  createAvatarCanvas(data: IUser): HTMLCanvasElement {
    const canvas = document.createElement("canvas");

    canvas.width = 300;
    canvas.height = 300;

    canvas.classList.add("user-avatar");

    minBlock({ canvasElement: canvas });

    if (data.user_name) {
      const firstLetter = data.user_name[0];
      const lastLetter = data.user_name.match(/\s(\w)/)?.[1] || "";
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.font = "bold 100px Arial";
        ctx.fillStyle = "#fff";
        ctx.strokeStyle = "#000";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(
          firstLetter + lastLetter,
          canvas.width / 2,
          canvas.height / 2,
        );
      }
    }

    const color = this.userColors.get(data.user_id);
    if (color) {
      canvas.style.borderColor = color;
    }

    this.userAvatars.set(data.user_id, canvas);
    this.onlineUsersAvatarsTarget.appendChild(canvas);
    return canvas;
  }

  refreshAvatarCanvas(data: IUser) {
    let canvas = this.userAvatars.get(data.user_id);
    if (!canvas) {
      canvas = this.createAvatarCanvas(data);
    }

    canvas.setAttribute("title", data.user_name);
    canvas.setAttribute("data-user-name", data.user_name);
    canvas.setAttribute("data-user-id", data.user_id);

    setTimeout(() => {
      if (canvas) {
        if (data.focus) canvas.classList.add("focus");
        else canvas.classList.remove("focus");
        canvas.classList.add("online");
      }
    });
  }

  removeAvatarCanvas(data: IUser) {
    const canvas = this.userAvatars.get(data.user_id);
    if (canvas) {
      canvas.remove();
      this.userAvatars.delete(data.user_id);
    }
  }

  onActiveUserOffline(data: IUser) {
    const user = this.findActiveUser(data);
    if (user) {
      this.removeAvatarCanvas(data);
      this.removeUserColor(data);
      this.activeUsers.delete(data.user_id);
    }
  }

  findActiveUser(data: IUser) {
    return this.activeUsers.get(data.user_id);
  }

  onFocusChange(focus: boolean) {
    if (this.focusTimer) clearTimeout(this.focusTimer);
    if (this.currentFocus === focus) return;
    this.focusTimer = window.setTimeout(() => {
      this.currentFocus = focus;
      this.sendMessage({ focus: focus ? "true" : "false" });
    }, 2000);
  }

  onLocationChange(location: string) {
    this.location = location;
    this.sendMessage({ location });
  }

  sendMessage(data: any) {
    this.messagesCount += 1;
    data.ts = Date.now() / 1000;
    data.id = this.messagesCount;
    this.logger.log("send", data);
    this.onlinePub.send(data);
    this.latestSentTimestamp = data.ts;
  }

  installWatcher(): void {
    setTimeout(() => {
      this.onLocationChange(getLocation());
    }, 1000);

    emitter.on("focus", (event) => this.onFocusChange(event as boolean));
    emitter.on("location", (event) => this.onLocationChange(event as string));

    setInterval(() => {
      const tick = Math.round(Date.now() / 1000);
      if (this.localReceivedTimestamp - this.remoteReceivedTimestamp > 5) {
        this.logger.log("connection lost");
        this.onlinePub.unsubscribe();
        this.connect();
      }
      if (tick % 30 === 0 && this.currentFocus) {
        this.onLocationChange(getLocation());
      }
    }, 1000);
  }

  removeWatcher(): void {
    // observer.disconnect();
  }
}
