/// <reference types="vite/client" />

import type { Application, Definition } from "@hotwired/stimulus";
import { ImportGlobFunction } from "vite";

export type ImportedModules = ReturnType<ImportGlobFunction>;
export type Entry<T> = { [K in keyof T]: [K, T[K]] }[keyof T];

export function controllerFilenameRegexp(
  rootPaths = ["controllers", "components", "views"],
) {
  // eslint-disable-next-line security/detect-unsafe-regex, security/detect-non-literal-regexp
  return new RegExp(
    `^(?:.*?(?:${rootPaths.join("|")})/|.?./)?(.+)(?:[/_-]controller..+?)$`,
  );
}

export function registerControllers(
  application: Application,
  controllerModules: ImportedModules,
) {
  application.load(definitionsFromGlob(controllerModules));
}

export function definitionsFromGlob(
  controllerModules: ImportedModules,
): Definition[] {
  return Object.entries(controllerModules)
    .map(definitionFromEntry)
    .filter((value) => value) as Definition[];
}

function definitionFromEntry([name, controllerModule]: Entry<ImportedModules>):
  | Definition
  | undefined {
  const identifier = identifierForGlobKey(name);
  const controllerConstructor = controllerModule.default;
  if (identifier && typeof controllerConstructor === "function")
    return { identifier, controllerConstructor };
}

export function identifierForGlobKey(key: string): string | undefined {
  const filenameRegexp = controllerFilenameRegexp();
  const logicalName = (key.match(filenameRegexp) || [])[1];
  if (logicalName) return logicalName.replace(/[^\p{L}\p{N}]+/gu, "-");
}
