interface BlockConfig {
  canvasID?: string;
  canvasElement?: HTMLCanvasElement;
  blocksPerEdge?: number;
  padding?: number;
  spacing?: number;
  color?: { primary: string; secondary: string };
}

interface BlockOptions {
  canvasID?: string;
  blocksPerEdge: number;
  padding: number;
  spacing: number;
  color: { primary: string; secondary: string };
}

export function minBlock(config: string | BlockConfig) {
  const colors: string[] = [
    "#1abc9c",
    "#2ecc71",
    "#3498db",
    "#9b59b6",
    "#f1c40f",
    "#e67e22",
    "#e74c3c",
  ];

  const options: BlockOptions = {
    blocksPerEdge: 5,
    padding: 20,
    spacing: 0,
    color: getRandomColorPair(),
  };

  if (typeof config === "string") {
    options.canvasID = config;
  } else if (
    typeof config !== "object" ||
    (!config.canvasID && !config.canvasElement)
  ) {
    return;
  }

  options.canvasID = options.canvasID || config.canvasID;
  options.blocksPerEdge = Math.max(config.blocksPerEdge || 5, 3);
  options.padding =
    typeof config === "object" && config.padding
      ? config.padding
      : options.padding;
  options.spacing =
    typeof config === "object" && config.spacing
      ? config.spacing
      : options.spacing;

  options.color =
    typeof config === "object" && config.color
      ? config.color
      : getRandomColorPair();

  const canvas: HTMLCanvasElement =
    config.canvasElement ||
    (document.getElementById(options.canvasID) as HTMLCanvasElement);
  const canvasWidth: number = canvas.width;
  const canvasHeight: number = canvas.height;
  const ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");

  const Height: number = canvasHeight - 2 * options.padding;
  const Width: number = canvasWidth - 2 * options.padding;
  const squareHeight: number = Height / options.blocksPerEdge;
  const squareWidth: number = Width / options.blocksPerEdge;

  function render() {
    canvas.style.background = options.color.primary;
    fillMatrix(options.color.secondary);
    randFill(options.color.primary);
  }

  function fillMatrix(color: string) {
    for (let i = 0; i < options.blocksPerEdge; i++) {
      for (let j = 0; j < options.blocksPerEdge; j++) {
        drawSquare(color, j, i);
      }
    }
  }

  function drawSquare(color: string, matrixX: number, matrixY: number): void {
    const x: number = options.padding + matrixX * squareWidth;
    const y: number = options.padding + matrixY * squareHeight;
    if (ctx !== null) {
      ctx.beginPath();
      ctx.fillStyle = color;
      ctx.rect(
        x + options.spacing,
        y + options.spacing,
        squareWidth - options.spacing * 2,
        squareHeight - options.spacing * 2,
      );
      ctx.fill();
      ctx.closePath();
    }
  }

  function getRandomColorPair(): { primary: string; secondary: string } {
    const colorPrimary: string =
      colors[Math.floor(Math.random() * colors.length)];
    let colorSecondary: string =
      colors[Math.floor(Math.random() * colors.length)];
    while (colorSecondary === colorPrimary) {
      colorSecondary = colors[Math.floor(Math.random() * colors.length)];
    }
    return { primary: colorPrimary, secondary: colorSecondary };
  }

  function getLocation(number: number): [number, number] {
    const a: number = Math.floor(number / options.blocksPerEdge);
    const b: number = number % options.blocksPerEdge;
    return [a, b];
  }

  function randFill(color: string) {
    const numBlocksReplace: number = getRandomInt(
      options.blocksPerEdge * options.blocksPerEdge * 0.25,
      options.blocksPerEdge * options.blocksPerEdge * 0.75,
    );
    const replacedBlocks: number[] = [];
    while (replacedBlocks.length < numBlocksReplace) {
      const rand: number = getRandomInt(
        0,
        options.blocksPerEdge * options.blocksPerEdge - 1,
      );
      if (replacedBlocks.indexOf(rand) === -1) {
        const coordinate: [number, number] = getLocation(rand);
        drawSquare(color, coordinate[1], coordinate[0]);
        replacedBlocks.push(rand);
      }
    }
  }

  function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  render();
}
