export default class Logger {
  DEBUG = process.env.NODE_ENV === "development";
  label = "Logger";

  constructor(label?: string) {
    if (label) this.label = label;
  }

  log(...args: any[]) {
    if (this.DEBUG) {
      console.log(this.label, ...args);
    }
  }
}
