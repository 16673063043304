import { createConsumer } from "@rails/actioncable";

const protocol = window.location.protocol === "https:" ? "wss" : "ws";
export const consumer = createConsumer(
  `${protocol}://${window.location.host}/cable`,
);

/*
  consumer.subscriptions.create(
    { channel: channelName },
    {
      received(data) {},
      appear() {},
      away() {},
      connected() {},
      disconnected() {},
      initialized() {},
      install() {},
      rejected() {},
      uninstall() {},
      update() {},
    }
  );
*/
